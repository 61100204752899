import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './i18n'; // i18n configuration
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Matches from './pages/Matches';
import MatchDetail from './pages/MatchDetail';
import Login from './pages/Login';
import Register from './pages/Register';
import ForgotPassword from './pages/ForgotPassword';
import Profile from './pages/Profile';
import SubscriptionUpgrade from './pages/SubscriptionUpgrade';
import NotFound from './pages/NotFound';
import LanguageSelector from './components/LanguageSelector';
import { AuthProvider } from './context/AuthContext';
import Tools from './pages/Tools';
import BetOfTheDay from './pages/BetOfTheDay';
import Footer from './components/Footer';
import TermsOfService from './pages/TermsOfService';
import PrivacyPolicy from './pages/PrivacyPolicy';
import CookiePolicy from './pages/CookiePolicy';
import { useTranslation } from 'react-i18next';

function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    // Check if a language preference is saved in localStorage
    const savedLanguage = localStorage.getItem('language');

    if (savedLanguage) {
      // Use the saved language if it exists
      i18n.changeLanguage(savedLanguage);
    } else {
      // Otherwise, use the browser's language if supported, or default to 'en'
      const browserLanguage = navigator.language || navigator.userLanguage;
      const supportedLanguages = ['en', 'de', 'es', 'it', 'pt', 'tr'];
      const languageCode = browserLanguage.slice(0, 2);

      if (supportedLanguages.includes(languageCode)) {
        i18n.changeLanguage(languageCode);
        localStorage.setItem('language', languageCode); // Save the browser's language as the initial preference
      } else {
        i18n.changeLanguage('en'); // Default language
        localStorage.setItem('language', 'en');
      }
    }
  }, [i18n]);

  return (
    <AuthProvider>
      <Router>
        <div className="bg-gray-900 min-h-screen text-white flex flex-col">
          <LanguageSelector />
          <Navbar />
          <div className="flex-1">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/matches" element={<Matches />} />
              <Route path="/matches/:matchId" element={<MatchDetail />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/subscription-upgrade" element={<SubscriptionUpgrade />} />
              <Route path="/tools" element={<Tools />} />
              <Route path="/bet-of-the-day" element={<BetOfTheDay />} />
              <Route path="/terms-of-service" element={<TermsOfService />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/cookie-policy" element={<CookiePolicy />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
          <Footer />
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
