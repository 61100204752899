import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Loading() {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="relative flex items-center justify-center">
        <div className="w-32 h-32 border-4 border-blue-400 border-solid rounded-full animate-spin-slow border-t-transparent absolute"></div>
        <div className="w-24 h-24 border-4 border-blue-500 border-solid rounded-full animate-spin-medium border-t-transparent absolute"></div>
      </div>
    </div>
  );
}

function BetOfTheDay() {
  const { t, i18n } = useTranslation();
  const [bets, setBets] = useState([]); // bets state'i başlangıçta boş bir dizi
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState(getDateTabs()[3]); // Başlangıç olarak bugün
  const [activeGroupTab, setActiveGroupTab] = useState('Bankers'); // Başlangıç olarak Bankers
  const navigate = useNavigate();

  const fetchBetOfTheDay = async (date) => {
    setIsLoading(true);
    setError(null);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`https://api.smartbettor.app/api/matches/bet-of-the-day?date=${date}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        
        // Gelen yanıtta 'error' alanı var mı kontrol et
        if (data.error) {
          setError(data.error);
        } else {
          setBets(data); // Bahisleri al
        }
      } else {
        setError(t('unexpected_error')); // Diğer beklenmeyen durumlar için genel hata mesajı
      }
    } catch (error) {
      setError(t('connection_error')); // Bağlantı hatası durumları için
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchBetOfTheDay(activeTab.date);
  }, [activeTab]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleGroupTabClick = (groupTab) => {
    setActiveGroupTab(groupTab);
  };

  const handleMatchClick = (matchId) => {
    navigate(`/matches/${matchId}`); // Navigate to /matches/:matchId route
  };

  function getDateTabs() {
    const tabs = [];
    const today = new Date();

    // Son 3 gün ve bugün
    for (let i = 3; i >= 0; i--) {
      const date = new Date(today);
      date.setDate(today.getDate() - i);
      const label = i === 0 ? t('today') : date.toLocaleDateString(i18n.language, { day: 'numeric', month: 'long' });
      tabs.push({ label, date: date.toISOString().split('T')[0] });
    }

    // Yarın
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    tabs.push({ label: t('tomorrow'), date: tomorrow.toISOString().split('T')[0] });

    return tabs;
  }

  const tabs = getDateTabs();

  // Aktif sekmeye göre bahisleri filtreleme
  const filteredBets = bets.filter(bet => bet.bet_type === activeGroupTab);

  return (
    <div className="container mx-auto p-6">
      {isLoading && <Loading />}
      <h2 className="text-3xl font-bold mb-4">{t('bet_of_the_day_title')}</h2>

      <div className="flex justify-center space-x-4 mb-6">
        {tabs.map((tab) => (
          <button
            key={tab.date}
            onClick={() => handleTabClick(tab)}
            className={`px-4 py-2 rounded-md ${
              activeTab.label === tab.label ? 'bg-blue-500 text-white' : 'bg-gray-800 text-gray-400'
            }`}
          >
            {tab.label}
          </button>
        ))}
      </div>

      {/* Bankers ve Slip Sekmeleri */}
      <div className="flex justify-center mb-6">
        <div className="inline-flex rounded-md shadow-sm" role="group">
          <button
            type="button"
            onClick={() => handleGroupTabClick('Bankers')}
            className={`px-4 py-2 border border-gray-700 ${
              activeGroupTab === 'Bankers' ? 'bg-blue-500 text-white' : 'bg-gray-800 text-gray-400'
            }`}
          >
            {t('bankers')}
          </button>
          <button
            type="button"
            onClick={() => handleGroupTabClick('Slip')}
            className={`px-4 py-2 border border-gray-700 ${
              activeGroupTab === 'Slip' ? 'bg-blue-500 text-white' : 'bg-gray-800 text-gray-400'
            }`}
          >
            {t('slip')}
          </button>
        </div>
      </div>

      {error ? (
        <div className="text-red-500">{error}</div>
      ) : filteredBets.length > 0 ? (
        <div className="bg-gray-800 p-4 rounded overflow-x-auto">
          <table className="w-full text-left table-auto border-collapse border border-gray-700">
            <thead>
              <tr className="bg-gray-700 border-b border-gray-700">
                <th className="px-4 py-2 border border-gray-700">{t('match')}</th>
                <th className="px-4 py-2 border border-gray-700">{t('bet_description')}</th>
                <th className="px-4 py-2 border border-gray-700">{t('match_date')}</th>
              </tr>
            </thead>
            <tbody>
              {filteredBets.map(bet => (
                <tr
                  key={bet.bet_id}
                  className="hover:bg-gray-700 border-b border-gray-700 cursor-pointer"
                  onClick={() => handleMatchClick(bet.match_id)}
                >
                  <td className="border px-4 py-2 border-gray-700">
                    {bet.home_team} vs {bet.away_team}
                  </td>
                  <td className="border px-4 py-2 border-gray-700">{bet.bet_description}</td>
                  <td className="border px-4 py-2 border-gray-700">
                    {new Date(bet.match_date).toLocaleDateString(i18n.language, { day: 'numeric', month: 'long', year: 'numeric' })}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p>{t('no_bet_of_the_day')}</p>
      )}
    </div>
  );
}

export default BetOfTheDay;
