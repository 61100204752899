import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';

function Loading() {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="relative flex items-center justify-center">
        <div className="w-32 h-32 border-4 border-blue-400 border-solid rounded-full animate-spin-slow border-t-transparent absolute"></div>
        <div className="w-24 h-24 border-4 border-blue-500 border-solid rounded-full animate-spin-medium border-t-transparent absolute"></div>
      </div>
    </div>
  );
}

function Matches() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const today = new Date(); // This will use the current date and time

  const defaultTab = { label: t('today'), date: today.toISOString().split('T')[0] };
  const [activeTab, setActiveTab] = useState(defaultTab);
  const [matches, setMatches] = useState([]);
  const [filteredMatches, setFilteredMatches] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [lastFetchedTab, setLastFetchedTab] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const generateTabs = () => {
    const tabs = [];
    const startDate = new Date(today);
    startDate.setDate(startDate.getDate() - 3);
  
    for (let i = 0; i < 7; i++) {
      const date = new Date(startDate);
      date.setDate(startDate.getDate() + i);
  
      // Use the translation hook for the "Today" label
      const label = i === 3 ? t('today') : date.toLocaleDateString(t('locale'), { day: 'numeric', month: 'long' });
  
      tabs.push({ label, date: date.toISOString().split('T')[0] });
    }
    return tabs;
  };
  

  const tabs = generateTabs();

  const fetchMatches = async (date) => {
    if (isLoading || (lastFetchedTab && lastFetchedTab.date === date)) return;
    setIsLoading(true);
    setLastFetchedTab({ date });
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`https://api.smartbettor.app/api/matches?date=${date}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        const updatedData = data.map(match => ({
          ...match,
          trust_2_5: match.trust_2_5 ? match.trust_2_5 * 10 : null,
          trust_both_teams: match.trust_both_teams ? match.trust_both_teams * 10 : null,
        }));
        setMatches(updatedData);
        setFilteredMatches(updatedData); // Initialize filtered matches
      } else {
        console.error('Maçlar alınamadı');
      }
    } catch (error) {
      console.error('Bir hata oluştu:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchMatches(activeTab.date);
  }, [activeTab.date]);

  useEffect(() => {
    if (searchQuery) {
      const filtered = matches.filter(match =>
        match.home_team.toLowerCase().includes(searchQuery.toLowerCase()) ||
        match.away_team.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredMatches(filtered);
    } else {
      setFilteredMatches(matches);
    }
  }, [searchQuery, matches]);

  const handleTabClick = (tab) => {
    if (tab.date !== activeTab.date) {
      setActiveTab(tab);
      fetchMatches(tab.date);
    }
  };

  const renderBTTSOrLock = (data) => {
    if (data === null || data === undefined || isNaN(data)) {
      return <FontAwesomeIcon icon={faLock} className="text-gray-400" />;
    }
    return (data === 1 ? t('btts_yes') : t('btts_no'));
  };

  const renderOverUnderOrLock = (data) => {
    if (data === null || data === undefined || isNaN(data)) {
      return <FontAwesomeIcon icon={faLock} className="text-gray-400" />;
    }
    return (data === 1 ? t('over') : t('under'));
  };

  const renderCorrectScoreOrLock = (predicted_home, predicted_away) => {
    if (predicted_home === null || predicted_home === undefined || isNaN(predicted_home)) {
      return <FontAwesomeIcon icon={faLock} className="text-gray-400" />;
    }
    return predicted_home + " - " + predicted_away;
  };

  const renderDataOrLock = (data) => {
    if (data === null || data === undefined || (typeof data === 'number' && isNaN(data))) {
      return <FontAwesomeIcon icon={faLock} className="text-gray-400" />;
    }
    return data;
  };

  const renderTrustOrLock = (data) => {
    if (data === null || data === undefined || (typeof data === 'number' && isNaN(data))) {
      return <FontAwesomeIcon icon={faLock} className="text-gray-400" />;
    }
    return data.toFixed(2);
  };

  const renderProgressBar = (value) => {
    if (value === null || value === undefined || value === 0 || isNaN(value)) {
      return;
    }
    return (
      <div className="relative pt-1">
        <div className="overflow-hidden h-4 text-xs flex rounded bg-gray-700 mt-1">
          <div
            style={{ width: `${value}%` }}
            className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-500"
          >
            {value.toFixed(1)}%
          </div>
        </div>
      </div>
    );
  };

  const determineBestBet = (match) => {
    if (match.best_bet === 'Yok') {
      return <FontAwesomeIcon icon={faLock} className="text-gray-400" />;
    }

    if (typeof match.best_bet === 'string') {
      return match.best_bet
        .replace('KG:1', t('btts_yes'))
        .replace('KG:0', t('btts_no'))
        .replace('Alt', t('under'))
        .replace('Üst', t('over'));
    }
    if (match.predicted_2_5 === 0) {
      return `2.5:${t('under')}`;
    }
    if (match.predicted_2_5 === 1) {
      return `2.5:${t('over')}`;
    }
    return match.both_teams_to_score ? t('btts_yes') : t('btts_no');
  };

  const handleMatchClick = (matchId) => {
    navigate(`/matches/${matchId}`);
  };

  return (
    <div className="container mx-auto p-6">
      {isLoading && <Loading />}
      <h2 className="text-3xl font-bold mb-4">{t('matches_title')}</h2>

      <div className="flex justify-center space-x-4 mb-6">
        {tabs.map((tab) => (
          <button
            key={tab.date}
            onClick={() => handleTabClick(tab)}
            className={`px-4 py-2 rounded-md ${
              activeTab.label === tab.label ? 'bg-blue-500 text-white' : 'bg-gray-800 text-gray-400'
            }`}
          >
            {tab.label}
          </button>
        ))}
      </div>

      <input
        type="text"
        placeholder={t('search')}
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className="w-full px-4 py-2 mb-4 rounded bg-gray-800 text-white border border-gray-700"
      />

      <div className="bg-gray-800 p-4 rounded overflow-x-auto">
        {filteredMatches.length > 0 ? (
          <table className="w-full text-left table-auto border-collapse border border-gray-700">
            <thead>
              <tr className="bg-gray-700 border-b border-gray-700">
                <th className="px-4 py-2 border border-gray-700">{t('match')}</th>
                <th className="px-4 py-2 border border-gray-700">{t('real_score')}</th>
                <th className="px-4 py-2 border border-gray-700">{t('predicted_goals')}</th>
                <th className="px-4 py-2 border border-gray-700">{t('predicted_2_5')}</th>
                <th className="px-4 py-2 border border-gray-700">{t('trust_2_5')}</th>
                <th className="px-4 py-2 border border-gray-700">{t('both_teams_to_score')}</th>
                <th className="px-4 py-2 border border-gray-700">{t('trust_both_teams')}</th>
                <th className="px-4 py-2 border border-gray-700">{t('best_bet')}</th>
              </tr>
            </thead>
            <tbody>
              {filteredMatches.map((match, index) => (
                <tr
                  key={index}
                  className="hover:bg-gray-700 border-b border-gray-700 cursor-pointer"
                  onClick={() => handleMatchClick(match.match_id)}
                >
                  <td className="border px-4 py-2 border-gray-700">{match.home_team} vs {match.away_team}</td>
                  <td className="border px-4 py-2 border-gray-700">
                    {match.status != "incomplete" ? match.home_score+" - "+match.away_score : " - "}
                  </td>
                  <td className="border px-4 py-2 border-gray-700">{renderCorrectScoreOrLock(match.predicted_home_goals, match.predicted_away_goals)}</td>
                  <td className="border px-4 py-2 border-gray-700">
                    <div>{renderOverUnderOrLock(match.predicted_2_5)}</div>
                    {renderProgressBar(match["2_5_probability"])}
                  </td>
                  <td className="border px-4 py-2 border-gray-700">{renderTrustOrLock(match.trust_2_5)}</td>
                  <td className="border px-4 py-2 border-gray-700">
                    <div>{renderBTTSOrLock(match.both_teams_to_score)}</div>
                    {renderProgressBar(match["both_teams_to_score_probability"])}
                  </td>
                  <td className="border px-4 py-2 border-gray-700">{renderTrustOrLock(match.trust_both_teams)}</td>
                  <td className="border px-4 py-2 border-gray-700">{renderDataOrLock(determineBestBet(match))}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>{t('no_predictions')}</p>
        )}
      </div>
    </div>
  );
}

export default Matches;
