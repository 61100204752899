// src/components/Navbar.jsx
import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { useTranslation } from 'react-i18next';
import { FaBars, FaTimes } from 'react-icons/fa'; // Hamburger ve kapama ikonları

function Navbar() {
  const { t } = useTranslation();
  const { isLoggedIn, user, logout } = useContext(AuthContext);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false); // Menü durumunu yönetmek için
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <nav className="bg-gray-800 p-4">
      <div className="container mx-auto flex justify-between items-center">
        <Link to="/" className="text-lg font-bold text-white">
          {t('smart_bettor')}
        </Link>
        <div className="md:hidden">
          {/* Hamburger menü ikonu */}
          <button onClick={() => setMenuOpen(!menuOpen)} className="text-white focus:outline-none">
            {menuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
          </button>
        </div>
        <div
          className={`flex-col md:flex-row md:flex items-center ${
            menuOpen ? 'flex' : 'hidden'
          } md:block w-full md:w-auto`}
        >
          <Link to="/bet-of-the-day" className="px-3 py-2 rounded-md text-sm font-medium text-white hover:bg-gray-700">
            {t('bet_of_the_day')}
          </Link>
          <Link to="/matches" className="px-3 py-2 rounded-md text-sm font-medium text-white hover:bg-gray-700">
            {t('matches')}
          </Link>
          <Link to="/tools" className="px-3 py-2 rounded-md text-sm font-medium text-white hover:bg-gray-700">
            {t('tools')}
          </Link>
          {isLoggedIn && user ? (
            <div className="flex flex-col items-start ml-4 relative" ref={dropdownRef}>
              <button
                onClick={toggleDropdown}
                className="px-3 py-2 rounded-md text-sm font-medium text-white hover:bg-gray-700"
              >
                {t('hello')}, {user.firstName} {user.lastName}
              </button>
              {dropdownVisible && (
                <div className="absolute top-full mt-2 w-48 bg-white rounded-md shadow-lg z-30">
                  <Link
                    to="/profile"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    onClick={() => setDropdownVisible(false)}
                  >
                    {t('profile')}
                  </Link>
                  <button
                    className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    onClick={handleLogout}
                  >
                    {t('logout')}
                  </button>
                </div>
              )}
            </div>
          ) : (
            <div className="flex flex-col items-start ml-4">
              <Link to="/login" className="px-3 py-2 rounded-md text-sm font-medium text-white hover:bg-gray-700">
                {t('login')}
              </Link>
              <Link to="/register" className="px-3 py-2 rounded-md text-sm font-medium text-white hover:bg-gray-700">
                {t('register')}
              </Link>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
